import React from 'react'
import $ from 'jquery'

class Skills extends React.Component {
    
    componentDidMount(){
        $('[data-progress]').each(function () {
            $(this).css({
                'bottom': $(this).data('progress')
            });
        });
        $('[data-color]').each(function () {
            $(this).css({
                'background-color': $(this).data('color')
            });
        });
    }
    render()
    {
        return (
            <React.Fragment>
                <section className="section skills py-5">
                        <div className='row mb-4'>
                            <div className="col-11 col-md-10 mx-auto">
                                <h2>Skills</h2>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-11 col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col-lg-6 col-12 column-gap">
                                        <div class="skill-badge"> 
                                            <h3>UI Design</h3>
                                            <p>I can bring passion and aesthetics to your project with experience on scientific proven creative concepts:</p>
                                            <ul className="">
                                                <li>Visual design</li>
                                                <li>Information Visualization</li>
                                                <li>Colorimetry</li>
                                                <li>Design systems</li>
                                                <li>Moodboards</li>
                                                <li>Tools: Figma, Adobe suite</li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-6 col-12 column-gap">
                                        <div class="skill-badge"> 
                                            <h3>Engineering</h3>
                                            <p>I can help closing the gap between Product / Engineering, anticipating feasibility and understanding:</p>
                                            <ul className="">
                                                <li>HTML / CSS / JavaScript (& Frameworks)</li>
                                                <li>WordPress, Webflow</li>
                                                <li>MS Teams UI and Phone message bots</li>
                                                <li>Omni-Channel Services via Jinja2: For Email, Retool, PDF Templates, etc</li>
                                                <li>JavaScript Visualization Frameworks</li>
                                            </ul>  
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-12 column-gap">
                                        <div class="skill-badge"> 
                                            <h3>UX Design</h3>
                                            <p>I can bring proven methodology to align the user needs with the business goals independently of the interface or technology using (to name a few):</p>
                                            <ul className="">
                                                <li>User Research</li>
                                                <li>Information Architecture</li>
                                                <li>Storytelling / Prototyping / High Fidelity</li>
                                                <li>Human Computer Interaction</li>
                                                <li>Testing</li>
                                            </ul>  
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12 column-gap">
                                        <div class="skill-badge"> 
                                            <h3>Service / Business</h3>
                                            <p>I can create broader perspective solutions to integrate the human delivering services as a part of the user experience, creating (to name a few):</p>
                                            <ul className="">
                                                <li>Business model canvas</li>
                                                <li>Service Blueprint</li>
                                                <li>Jobs to be done</li>
                                            </ul>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
            </React.Fragment>
        )
    }
}

export default Skills;